import React, { useState, useRef, useEffect } from 'react'
import AI_logo from '../Assests/images/AI (1).png'
import mobilemockup from '../Assests/images/only mobile mock up.png'
import Well_logo from '../Assests/images/Well.png'
import AIDOcfirstlogo from '../Assests/images/AI_doc.png'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AIDOclastlogo from '../Assests/images/DOC.png'
import LaptopMockup from '../Assests/images/Laptop_mockup.png'
import AIKiosk from '../Assests/images/AI_kiosk.png'
import { motion, useAnimation } from 'framer-motion'
import splash from '../Assests/raw/scientifically crafted for you rotation (1).json'
import Kioskimg from '../Assests/images/Kiosk.png'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import AIkiosk1 from '../Assests/images/Aikiosk_1.png'
import 'intersection-observer';

import AIkiosk2 from '../Assests/images/Aikiosk_2.png'
import AIkiosk3 from '../Assests/images/AiKiosk_3.png'
import AIKioskhovered1 from '../Assests/images/Aikiosk_hover_icon1.png'
import AIKioskhovered2 from '../Assests/images/Aikiosk_hover_icon2.png'
import AIKioskhovered3 from '../Assests/images/Aikiosk_hover_icon3.png'
import AIDOc1 from '../Assests/images/Aidoc_1.png'
import AIDOc2 from '../Assests/images/Aidoc_2.png'
import AIDOc3 from '../Assests/images/Aidoc_3.png'
import AIDOChovered1 from '../Assests/images/Aidoc_hover_icon1.png'
import AIDOChovered2 from '../Assests/images/Aidoc_hover_icon2.png'
import AIDOChovered3 from '../Assests/images/Aidoc_hover_icon3.png'

import Aiwellimg1 from '../Assests/images/Aiwell_1.png'
import Aiwellimg2 from '../Assests/images/Aiwell_2.png'
import Aiwellimg3 from '../Assests/images/Aiwell_3.png'
import Aiwellimg4 from '../Assests/images/Aiwell_4.png'
import Aiwellimg5 from '../Assests/images/Aiwell_5.png'

import Aiwellimghovered1 from '../Assests/images/Aiwell_hover_icon1.png'
import Aiwellimghovered2 from '../Assests/images/Aiwell_hover_icon2.png'
import Aiwellimghovered3 from '../Assests/images/Aiwell_hover_icon3.png'
import Aiwellimghovered4 from '../Assests/images/Aiwell_hover_icon4.png'
import Aiwellimghovered5 from '../Assests/images/Aiwell_hover_icon5.png'
import { useInView } from 'react-intersection-observer'
import kioskwordimg from '../Assests/images/Kiosk_word.png'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Button } from '@mui/material'
import Lottie from 'lottie-react'

function Ayurai_Products() {

    const [hoveredIndex, setHoveredIndex] = useState(null);
    const scrollRef = useRef(null);



    const [ref, inView] = useInView({
        threshold: 0.2
    })

    const controlsLeft3 = useAnimation();
    const controlsBottom3 = useAnimation();
    const controlsRight3 = useAnimation();


    const [ref3, inView3] = useInView({
        threshold: 0.2,
    });

    const parentRef = useRef(null);

    const controlsLeft = useAnimation();
    const controlsBottom = useAnimation();
    const controlsRight = useAnimation();

    useEffect(() => {
        if (inView) {
            controlsLeft.start({ x: 0, opacity: 1, transition: { type: "spring", stiffness: 100, damping: 25, duration: 1, delay: 0.5 } });
            controlsBottom.start({ y: 0, opacity: 1, transition: { type: "spring", stiffness: 100, damping: 25, duration: 1, delay: 1 } });
            controlsRight.start({ x: 0, opacity: 1, transition: { type: "spring", stiffness: 100, damping: 25, duration: 1, delay: 1.5 } });
        } else {
            controlsLeft.start({ x: "-100vw", opacity: 0 });
            controlsBottom.start({ y: "100vh", opacity: 0 });
            controlsRight.start({ x: "100vw", opacity: 0 });
        }
    }, [inView, controlsLeft, controlsBottom, controlsRight]);







    useEffect(() => {
        if (inView3) {
            // Second Div: Comes from below
            controlsBottom3.start({
                y: 0,
                opacity: 1,
                transition: { type: "spring", stiffness: 100, damping: 25, duration: 1 },
            });

            // First Div: Moves from behind the second div (right to left)
            controlsLeft3.start({
                x: "0",
                opacity: 1,
                transition: {
                    type: "spring",
                    stiffness: 100,
                    damping: 25,
                    duration: 1,
                    delay: 1,
                },
            });

            // Third Div: Moves from behind the second div (left to right)
            controlsRight3.start({
                x: "0%",
                opacity: 1,
                transition: {
                    type: "spring",
                    stiffness: 100,
                    damping: 25,
                    duration: 1,
                    delay: 2,
                },
            });
        } else {
          
            controlsLeft3.start({ x: "50%", opacity: 0 }); // First Div starts behind second div (right)
            controlsBottom3.start({ y: "100vh", opacity: 0 }); // Second Div starts below viewport
            controlsRight3.start({ x: "-50%", opacity: 0 }); // Third Div starts behind second div (left)
        }
    }, [inView3, controlsLeft3, controlsBottom3, controlsRight3]);


    const scrollLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: -300, behavior: "smooth" });
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: 300, behavior: "smooth" });
        }
    };

    const secondDivVariant = {
        hidden: { opacity: 0, y: 100 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
    };

    const firstDivVariant = {
        hidden: { x: 100, opacity: 0 },
        visible: {
            x: 0,
            opacity: 1,
            transition: { duration: 0.8, delay: 0.8 },
        },
    };

    const thirdDivVariant = {
        hidden: { x: -100, opacity: 0 },
        visible: {
            x: 0,
            opacity: 1,
            transition: { duration: 0.8, delay: 1.6 },
        },
    };

    const Aiwellfeatures =
        [
            {
                title: "Ayurveda Assessment",
                info: "AI driven Prakriti & Vikriti assessment",
                cardimg: Aiwellimg1,
                cardimghovered: Aiwellimghovered1
            },
            {
                title: "Advanced Biomarkers",
                info: "Digital pulse, blood & gut biomarkers",
                cardimg: Aiwellimg2,
                cardimghovered: Aiwellimghovered2
            },
            {
                title: "Expert Guidance",
                info: "Access to qualified Ayurvedic physicians for teleconsultations ",
                cardimg: Aiwellimg3,
                cardimghovered: Aiwellimghovered3
            },
            {
                title: "Holistic Recommendations",
                info: "Customized diet, recipes, yoga, meditation practices & herbal products",
                cardimg: Aiwellimg4,
                cardimghovered: Aiwellimghovered4
            },
            {
                title: "360 Health Improvement",
                info: "Tailored wellness packages & holistic improvement of health",
                cardimg: Aiwellimg5,
                cardimghovered: Aiwellimghovered5
            },
        ]



    const AIDOCfeatures =
        [
            {
                title: "Integrative Approach",
                info: "Combining Ayurveda with modern diagnostic markers for personalized health insights",
                cardimg: AIDOc1,
                cardimghovered: AIDOChovered1
            },
            {
                title: "Real-time Monitoring",
                info: "CGM data for personalized recommendations & better diabetes management",
                cardimg: AIDOc2,
                cardimghovered: AIDOChovered2
            },
            {
                title: "Diabetes Care",
                info: "Holistic care targeting insulin resistance & dyslipidemia through Ayurveda, diet, yoga, and meditation",
                cardimg: AIDOc3,
                cardimghovered: AIDOChovered3
            }
        ]

    const Kioskfeatures =
        [
            {
                title: "Personalized Assessment",
                info: "Discover your unique constitution (Prakriti) & imbalances (Vikriti) to create a tailored approach for optimal well-being",
                cardimg: AIkiosk1,
                cardimghovered: AIKioskhovered1
            },
            {
                title: "Health Monitoring",
                info: "Track key vital signs (BP, pulse, blood oxygen) and body composition (fat, muscle) for proactive health management",
                cardimg: AIkiosk2,
                cardimghovered: AIKioskhovered2
            },
            {
                title: "Holistic Care",
                info: "Tailored Ayurvedic recommendations, including diet, yoga, meditation & herbal supplements",
                cardimg: AIkiosk3,
                cardimghovered: AIKioskhovered3
            }
        ]

    return <div className='bg-[#FAF8F3]'>
        <section>
            <div className='Ayurai-products' ref={ref}>
                <p>Ayur. AI Products</p>
                <p>To deliver longevity at scale using personalized,<br />
                    integrative approach for Billion+ people</p>

                <div className='flex items-center mt-8' ref={parentRef}>
                    <motion.div
                        initial={{ x: "-100vw", opacity: 0 }}
                        animate={controlsLeft}
                    >
                        <img src={AI_logo} alt="AI Logo" />
                    </motion.div>

                    {/* Bottom to Top Animation */}
                    <motion.div
                        className="mobilemockup"
                        initial={{ y: "100vh", opacity: 0 }}
                        animate={controlsBottom}
                    >
                        <img src={mobilemockup} alt="Mobile Mockup" />
                    
                    </motion.div>

                    {/* Right to Left Animation */}
                    <motion.div
                        initial={{ x: "100vw", opacity: 0 }}
                        animate={controlsRight}
                    >
                        <img src={Well_logo} alt="Well Logo" />
                    </motion.div>
                </div>

                {/* <Lottie animationData={splash} style={{width:'250px'}}/> */}

                <div className="p-2 app-more-info"
                    style={{ display: "flex" }}>
                    <div className="app-more-info-item mt-5" >
                        <p className='mt-5'>Transform Your Health Journey with Aiwell: <br />
                            Your Personal Wellness Companion</p>
                        <p className='mt-5'>Start Your Wellness Journey Here &nbsp;
                            <KeyboardDoubleArrowRightIcon />
                        </p>
                    </div>
                </div>

            </div>
        </section>

        <section>
            <div className='App-features'>
                <p>Why AiWell ?</p>
                <div className='App-features-div' ref={scrollRef}>
                    {Aiwellfeatures.map((feature, index) => (
                        <div
                            key={index}
                            className={`card ${hoveredIndex === index ? "bg-[#DA6E0B]" : "bg-[#F4D2B3]"}`}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                        >
                            <div className="App-features-card-content">
                                <div className='App-features-card-content-img'>
                                    <img src={
                                        hoveredIndex === index ? feature.cardimghovered : feature.cardimg}></img>
                                </div>

                                {hoveredIndex === index ? (
                                    <p className="App-features-card-info"
                                        style={{ color: "#F4D2B3" }}>{feature.info}</p>
                                ) : (
                                    <h3 className="App-features-card-title" style={{
                                        color: "#3B362A"
                                    }}>{feature.title}</h3>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "1rem" }}>
                    <Button onClick={scrollLeft}
                        sx={{
                            backgroundColor: "#483934",
                            color: "#FDFDFD",
                            padding: "10px"
                        }}
                        className="scroll-btn" style={{ marginRight: "1rem" }}>
                        <KeyboardArrowLeftIcon />
                    </Button>
                    <Button onClick={scrollRight}
                        sx={{
                            backgroundColor: "#483934",
                            color: "#FDFDFD",
                            padding: "10px"
                        }}
                        className="scroll-btn">
                        <KeyboardArrowRightIcon />
                    </Button>
                </div>
            </div>
        </section>

        <section>
            <div className='Ayurai-products'>
                <div>
                    <div className='flex items-center mt-8'>
                        <div >
                            <img src={AIDOcfirstlogo}></img>
                        </div>

                        <div className=''
                        >
                            <img src={LaptopMockup}></img>
                        </div>

                        <div >
                            <img src={AIDOclastlogo}>
                            </img>

                        </div>
                    </div>

                    <div className="p-2 app-more-info"
                        style={{ display: "flex" }}>
                        <div className="app-more-info-item mt-5" >
                            <p className='mt-5'>Chronic Disease management application  </p>
                            <p className='mt-5' style={{ color: "#303F9F" }}>
                                Balance your Imbalances from today!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div className='App-features'>
                <p>Why AI Doc ?</p>
                <div className='App-features-div justify-between items-center' >
                    {AIDOCfeatures.map((feature, index) => (
                        <div
                            key={index}
                            className={`card ${hoveredIndex === index ? "bg-[#303F9F]" : "bg-[#DEE2FF]"}`}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                        >
                            <div className="App-features-card-content">
                                <div className='App-features-card-content-img'>
                                    <img src={
                                        hoveredIndex === index ? feature.cardimghovered : feature.cardimg}></img>
                                </div>

                                {hoveredIndex === index ? (
                                    <p className="App-features-card-info"
                                        style={{ color: "#CAEAE9" }}>{feature.info}</p>
                                ) : (
                                    <h3 className="App-features-card-title" style={{
                                        color: "#3B362A"
                                    }}>{feature.title}</h3>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>

        <section>
            <div className='Ayurai-products' ref={ref3}>
                <div>
                    <div className='flex items-center mt-8'>
                        <motion.div
                            
                            className='firstlogo'
                            animate={controlsLeft3}
                        
                            initial={{ x: "", opacity: 0 }}
                        >
                            <img src={AIKiosk} alt="AI Kiosk" />
                        </motion.div>

                        {/* Second Div (Reveals from behind, bottom to center) */}
                        <motion.div
                            animate={controlsBottom3}
                            initial={{ y: "100vh", opacity: 0 }}
                            className="kioskmockup"
                           
                        >
                            <img src={Kioskimg} alt="Kiosk Mockup" />
                        </motion.div>

                        {/* Third Div (Left to right) */}
                        <motion.div
                            animate={controlsRight3}
                            className='firstlogo'
                            initial={{ x: "", opacity: 0 }}
                        >
                            <img src={kioskwordimg} alt="Kiosk Word" />
                        </motion.div>
                    </div>

                    <div className="p-2 app-more-info"
                        style={{ display: "flex" }}>
                        <div className="app-more-info-item mt-5" >
                            <p className='mt-5'>For Ayurveda Phenotyping & Personalized<br />
                                Recommendations</p>
                            <p className='mt-5' style={{ color: "#1C8686" }}>
                                Step into a new era of wellness &nbsp;
                                <KeyboardDoubleArrowRightIcon />
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <section >
            <div className='App-features'>
                <p>Why AI Kiosk ?</p>
                <div className='App-features-div justify-between items-center' >
                    {Kioskfeatures.map((feature, index) => (
                        <div
                            key={index}
                            className={`card ${hoveredIndex === index ? "bg-[#1C8686]" : "bg-[#CAEAE9]"}`}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                        >
                            <div className="App-features-card-content">
                                <div className='App-features-card-content-img'>
                                    <img src={
                                        hoveredIndex === index ? feature.cardimghovered : feature.cardimg}></img>
                                </div>

                                {hoveredIndex === index ? (
                                    <p className="App-features-card-info"
                                        style={{ color: "#CAEAE9" }}>{feature.info}</p>
                                ) : (
                                    <h3 className="App-features-card-title" style={{
                                        color: "#3B362A"
                                    }}>{feature.title}</h3>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>

    </div>
}

export default Ayurai_Products

import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomeScreen from './Compontents/Home/HomeScreen';
import Homepage from './Compontents/Home/Homepage';
import Team from './Compontents/Teams/Team';
import 'intersection-observer';
import Contact from './Compontents/Contact/Contact';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return <BrowserRouter>
    <ToastContainer />
    <Routes>

      <Route path="/" element={<HomeScreen />}>
      
        <Route index element={<Homepage />} />
        <Route path="team" element={<Team />} />
        <Route path='contact' element={<Contact />} />

      </Route>

    </Routes>
  </BrowserRouter>
}

export default App;

import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import year2021 from '../Assests/images/2021_icon.png'
import year2022 from '../Assests/images/2022_icon.png'
import year2023 from '../Assests/images/2023_icon.png'
import year2024 from '../Assests/images/2024_icon.png'

function About() {
    return <div className='bg-[#483934]' id='about'>
        <div className='About-section'>
            <p>Journey of Ayur.AI</p>
            <p className='mt-5'>We aim to extend the healthy life of individuals by 20+ years by combining<br />
                the best of Ayurveda & modern science <span>-Mission 130<sup>+</sup></span></p>

            <div className='mt-5'>
                <VerticalTimeline lineColor="#BED040">
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"

                        contentStyle={{ background: 'none', color: '#fff', padding: "5px" }}
                        contentArrowStyle={{ borderRight: 'none' }}
                        date="2021"
                        iconStyle={{ background: "#BED040", outline: "none", padding: "5px",boxShadow:"" }}
                        icon={<img src={year2021}></img>}
                    >
                        <p>
                            Ayur.AI started to create an revolution in integrative <br /> health
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2022"
                        contentStyle={{ background: 'none', color: '#fff', padding: "5px" }}
                        contentArrowStyle={{ borderRight: 'none' }}
                        iconStyle={{ background: "#BED040", color: '#fff' }}
                        icon={<img src={year2022}></img>}
                    >
                        <p>
                            Clinical studies for AI training
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2023"
                        contentStyle={{ background: 'none', color: '#fff', padding: "5px" }}
                        contentArrowStyle={{ borderRight: 'none' }}
                        iconStyle={{ background: "#BED040", color: '#fff' }}
                        icon={<img src={year2023}></img>}
                    >
                        <p>
                            Aiwell - Pilot launched
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date={<span style={{
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: '1.2rem',
                            // backgroundColor: 'yellow',
                            padding: '5px',
                            borderRadius: '5px',
                        }} >
                            2024
                        </span>}
                        contentStyle={{ background: 'none', padding: "5px" }}
                        contentArrowStyle={{ borderRight: 'none', border: "none" }}
                        iconStyle={{ background: "#BED040", color: '#fff' }}
                        icon={<img src={year2024}></img>}
                    >
                        <p>
                            AI Well for integrative health & wellness
                        </p>
                        <p>Ai Prameha pilot launch for diabetes management & reversal​</p>
                        <p>Ai Kiosk pilot launch in Gujarat</p>
                    </VerticalTimelineElement>




                </VerticalTimeline>
            </div>
        </div>

    </div>

}

export default About
import React from 'react'
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Button, TextField, InputAdornment } from '@mui/material';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import MailIcon from '@mui/icons-material/Mail';
import FacebookIcon from '@mui/icons-material/Facebook';
import AyuraAIlogo from '../Assests/images/AyurAI_logo_white.png'

function Footer() {
    return <div className='footer-section'>
        <div className='flex md:flex-row flex-col justify-between items-center Subscribe-newsletter 
        gap-5'>
            <div>
                <p>Subscribe to Newsletter</p>
                <p>Stay informed about our latest health innovations.</p>
            </div>

            <div className='Subscribe-newsletter-content'>
                <TextField
                    id="outlined-required"
                    color="primary"
                    placeholder="Enter your email"
                    sx={{
                        width: '100%',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#FDFDFD', // Default border color
                            },
                            '&:hover fieldset': {
                                borderColor: '#FDFDFD', // Border color on hover
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#FDFDFD', // Border color on focus
                            },
                        },
                        '& .MuiInputBase-input': {
                            color: '#FDFDFD', // Text color
                        },
                        '& .MuiOutlinedInput-input': {
                            color: '#FDFDFD', // Text color for outlined input
                        },
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button
                                    // variant="contained"
                                    sx={{
                                        backgroundColor: 'transparent',
                                        color: '#BED040',
                                        padding: '5px 10px',
                                        textTransform: 'none', // Disable uppercase
                                        fontSize: '0.875rem', // Adjust font size

                                    }}
                                >
                                    Subscribe
                                </Button>
                            </InputAdornment>
                        ),
                    }}
                />

                <p className='mt-2'>By joining, you will receive a monthly newsletter</p>
            </div>
        </div>


        <div className='flex flex-col md:flex-row justify-between Footer-quick-sections'>

            <div>
                <div>
                    <p>Follow us on</p>
                </div>
                <div className='social-media  mt-5'>

                    <div className='flex flex-row gap-5 items-center' onClick={() => window.open('https://www.instagram.com/ayur.ai_/')}>
                        <div>
                            <InstagramIcon /></div>

                        <p>Instagram</p>

                    </div>

                    <div className='flex flex-row gap-5 items-center' onClick={() => window.open('https://www.facebook.com/ayuraiinnovations')}>
                        <div>
                            <i class="fa-brands fa-facebook-f"></i>

                        </div>

                        <p>Facebook</p>
                    </div>

                    <div className='flex flex-row gap-5 items-center' onClick={() => window.open('https://www.linkedin.com/company/ayurai/')}>
                        <div>

                            <i class="fa-brands fa-linkedin-in"  ></i>
                        </div>
                        <p>Linkedin</p>
                    </div>

                    <div className='flex flex-row gap-5 items-center' onClick={() => window.open('https://twitter.com/Ayur_AI')}>

                        <div>
                            <i class="fa-brands fa-x-twitter" onClick={() => window.open('https://twitter.com/Ayur_AI')}></i>
                        </div>
                        <p>Twitter</p>
                    </div>
                </div>
            </div>

            <div>
                <div>
                    <p>Get in Touch</p>
                </div>

                <div className='Contact-us mt-5'>

                    <a href='/contact'>
                        <p>Contact us</p>
                    </a>

                    <a href='tel:+919363135851'>
                        <div className='flex flex-row items-center gap-5 mt-5'>
                            <PhoneAndroidIcon />
                            <p>9363135851</p>
                        </div>
                    </a>

                    <a href='mailto:contact@ayurai.io'>
                        <div className='flex flex-row items-center gap-5 mt-2'>
                            <MailIcon />
                            <p>contact@ayurai.io</p>
                        </div>
                    </a>

                </div>

            </div>

            <div>
                <div>
                    <p>Legal</p>
                </div>
                <div className='mt-5'>
                    
                    <a href='/termsandcondition.html'>
                        <p className='mt-5'>Terms & Conditions</p>
                    </a>

                    <a href='/privacypolicy.html'>
                        <p className='mt-5'>Privacy Policy</p>
                    </a>

                    <a href='/returnpolicy.html'>
                        <p className='mt-5'>Return & refund policy</p>
                    </a>

                </div>
            </div>


        </div>


        <div className='mt-5 flex flex-col md:flex-row gap-5 items-center justify-between footer-logo'>
            <div className="navbar-logo">
                <img src={AyuraAIlogo} alt="Logo" />
            </div>
            <div>
                <p>&#169; 2024 Ayur.Ai All rights reserved.</p>
            </div>
        </div>
    </div>
}

export default Footer
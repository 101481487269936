import React from 'react'
import ayuredaprinciples from '../Assests/images/Ayurvedic Principles.png'
import deeptech from '../Assests/images/Deep Ayurveda Phenotyping.png'
import digitaldevices from '../Assests/images/Digital Devices.png'
import gutbiome from '../Assests/images/Gut_microbiome.png'
import AIndML from '../Assests/images/AI & ML.png'

function Discover() {
    return <section>
        <div className='discover-container'>
            <div className='discover-container-text flex flex-col gap-5'>
                <p>Discover the Power of Integrative <br /> Wellness: Traditional Meets Modern Science</p>
                <p>As a deeptech company, AyurAI utilizes advanced digital & blood biomarkers, genomics, and
                    cutting-edge AI to deliver personalized Ayurveda for wellness and chronic disease management </p>
            </div>
            <div className='parent-container'>
                <div className="parent">
                    <div className="div1 flex flex-col text-end"><h3>Ayurvedic Principles</h3>
                        <img src={ayuredaprinciples}></img></div>
                    <div className="div10 flex flex-col-reverse"><h3>Digital Devices</h3>
                        <img src={digitaldevices}>
                        </img></div>
                    <div className="div8 flex flex-col-reverse"><h3>Deep Ayurveda
                        Phenotyping</h3>
                        <img src={deeptech} />
                    </div>
                    <div className="div14 flex flex-col-reverse text-end"><h3>
                        AI & ML
                    </h3>
                        <img src={AIndML} /></div>
                    <div className="div12 flex flex-col-reverse"><h3>Gut
                        Microbiome</h3>
                        <img src={gutbiome} /></div>
                </div>

                {/* <div className="grid">
                    <div className="grid-item large">
                        <h3>Gut Microbiome</h3>
                        <p>Exploring the connection between Ayurveda and the microbiome.</p>
                    </div>
                    <div className="grid-item small">
                        <h3>Ayurvedic Principles</h3>
                        <p>Foundation of ancient wisdom guiding modern wellness.</p>
                    </div>
                    <div className="grid-item small">
                        <h3>Digital Devices</h3>
                        <p>Integrating wearable tech and digital health solutions.</p>
                    </div>
                    <div className="grid-item medium">
                        <h3>Deep Ayurveda Phenotyping</h3>
                        <p>Precision-based analysis for holistic care.</p>
                    </div>
                    <div className="grid-item small">
                        <h3>AI & ML</h3>
                        <p>Leveraging artificial intelligence to enhance personalized care.</p>
                    </div>
                </div> */}
            </div>
        </div>
    </section>
}

export default Discover
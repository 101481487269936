export const carouselData = [
    {
        id: 1,
        title: "Prabodha 2024 June Edition - Speaker Dr Bala Pesala",
        date: "August, 2024",
        description: "In this talk Dr. Bala Pesala talked about the role of AI in Ayurveda.This lecture was a part of the efforts of Team MAITHRI, the Alumni Association of SJSAC to conduct monthly lectures delivered by Ayurveda professionals and elites from other fields as well Enjoy the talk ",
        iframe: "<iframe width='100%' height='100%'src='https://www.youtube.com/embed/smhqLj72miA?si=sxpLh6oLIeebB-Yc' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' referrerpolicy='strict-origin-when-cross-origin' allowfullscreen></iframe>"
    },
    {
        id: 1,
        title: "PODCAST  The ARB Show Season 2 | Episode 28  Top 5 trends in Ayurveda",
        date: "August, 2024",
        description: "In this episode, Dr. Bala Pesala, was recently featured on The ARB Show, where he discussed the Top 5 Trends in Ayurveda that are shaping the future of health and wellness.",
        iframe: '<iframe src="https://www.youtube.com/embed/ivMlpFgFoD8?si=-jNTXNrRnx7Di13v" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>'
    },
    {
        id: 3,
        title: "Ayurveda 2.0: Bridging AI and Integrative Medicine.",
        date: "December, 2023",
        description: "Keynote by Dr. Bala Pesala explored the synergy of Ayurveda and AI in healthcare. A pivotal event hosted in collaboration with NIA, Jaipur and My CWC India, highlighting the future of integrative medicine.",
        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/Ayurai_website/Ayurveda_2.0.png",
        link: "https://www.youtube.com/live/9Is9qBepnIs?si=M3OLJniJJCCaT-9i"
    },

    {
        id: 4,
        title: "Exploring the Integration of AI & Ayurveda",
        date: "December, 2023",
        description: "Ayurveda 2.0 # Session: Dr. Bala Pesala in a candid Conversation with Dr. Rashmi Jain",
        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/Ayurai_website/Exploring+the+Integration+of+AI+%26+Ayurveda.png",
        link: "https://www.youtube.com/live/kvqTpnJFV14?si=ou80rgZtzfLFZsp3"
    },
    {
        id: 5,
        title: "Applications of AI in Ayurveda",
        date: "December, 2023",
        description: "Through the Lens of a Doctor- An Insightful discussion between a Scientist , anAyurveda Doctor and Allopathic Doctor",
        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/Ayurai_website/Application+of+AI+in+ayurveda.png",
        link: "https://www.youtube.com/live/SO6ViEUwvLE?si=PjWZPH4jYnHvHAtf"
    },
    {
        id: 6,
        title: "Synergising the Health-Tech Ecosystem",
        date: "July, 2023",
        description: "Panel Discussion on AI Blueprint for Synergising the Health-Tech Ecosystem",
        iframe: '<iframe src="https://www.youtube.com/embed/GRhSPatZAqc?si=xKKFqN_2gnWsaoRn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>'
    },
    {
        id: 7,
        title: "Amalgamating Ayurved With AI",
        date: "July, 2023",
        description: "The technologies of today are helping make traditional medicine more potent with  AI.",
        iframe: '<iframe src="https://www.youtube.com/embed/qS4QgJWwGOQ?si=5L4iSB0b4AmcTz7G" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>'
    },
    {
        id: 8,

        title: "Ayushtech Startup of the Year at AIM2023",

        date: "June, 2023",

        description: "Ayur.AI is proud to receive Exemplary Ayushtech Startup of the Year at AIM2023.Thank you Minfy team for the recognition.",

        img: 'https://aiwellassets.s3.ap-south-1.amazonaws.com/Ayurai_website/Ayushtech_startup+of+the+year+at+AIM+2023.png',

        link: "https://www.linkedin.com/posts/ayurai_ayurveda-ai-integrativemedicine-activity-7079460816835493889-wN3Q?utm_source=share&utm_medium=member_desktop"
    },

    {
        id: 9,

        title: "9th World Ayurveda Congress & Arogya Expo in Panjim, Goa",

        date: "December, 2022",

        description: "The Ayur.AI team attended the 9th World Ayurveda Congress & Arogya Expo in Panjim, Goa. At the conference, we also presented our key scientific research findings towards using AI techniques for Ayurveda diagnostics. We have also presented first of its kind study investigating reduction in the inflammatory markers post Ayurveda treatment. We are glad to meet and interact with important Ayurvedic personalities at the conference.",

        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/Ayurai_website/9th+ayurveda+congress.png",

        link:"https://www.linkedin.com/posts/ayurai_ayurai-team-at-9th-world-ayurveda-congress-activity-7008389626050596864-NncJ?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 10,

        title: "TIH Foundation for IoT and IoE (TIH-IoT) at IIT-Bombay",

        date: "December, 2022",

        description: "Great to interact with DST secretary Dr. S. Chandra Shekhar and for his valuable suggestions to make Ayurveda more evidence based through rigorous clinical studies. Thanks TIH Foundation for IoT and IoE (TIH-IoT) at IIT-Bombay for offering the opportunity and extending your support.",

        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/Ayurai_website/TIH+foundation.png",

        link:"https://www.linkedin.com/posts/ayurai_ayurveda-tih-iot-activity-7009037832237309952-E0yb?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 11,

        title: "AIM prime program",

        date: "May, 2022",

        description: "Team Ayur.AI successfully completed 9 month AIM prime program conducted by Venture Center. Our team also participated in launch of AIM prime play book program held at Dr. Ambedkar international centre on 10 May, 2022. Dr. Bala Pesala, demonstrated Ayur.AI apps to Dr. Bharati Pravin Pawar, Honourable Union Minister of State, Ministry of Health and Family Welfare, along with Mr. Suman Bery, Vice-Chair NITI Aayog, Dr.VK Paul, Member, NITI Aayog, Dr. Chintan Vaishnav, Mission Director, Atal Innovation Mission, Dr. Premnath, Director, Venture Center.",

        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/Ayurai_website/AIM+Prime+program.png",

        link:"https://www.linkedin.com/posts/ayurai_team-innovation-ai-activity-6942783689869127681-zroj?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 12,

        title: "Global Ayush Investment & Innovation Summit 2022",

        date: "April, 2022",

        description: "Ministry of AYUSH and All India Institute of Ayurveda selected Ayur.AI to exhibit their products in Global AYUSH innovation and investment summit 2022 held at Ahmedabad, Gujarat.",

        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/Ayurai_website/Global+Ayush+Investment.png",

        link:"https://www.linkedin.com/posts/ayurai_ayurveda-ayush-activity-6926590134419165184-Uh3i?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 13,

        title: "Medical Camps",

        date: "September, 2021",

        description: "The team Ayur.AI along with the NASYA Tamil Nadu chapter conducted medical camps at National Institute of Ocean Technology and Council for Scientific and Industrial Research. The Ayur.AI team assessed Prakriti and circulated their app to the camp participants. The medicines were supported by NASYA team doctors. Our team demonstrated the app for 150+ participants.",

        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/Ayurai_website/Medical_camps.png"
    },

];
import React from 'react';
import AIM_logo from '../Assests/images/AIM_prime.png';
import Indus_logo from '../Assests/images/Indus_BioTech.png';
import IOT_logo from '../Assests/images/The_foundation_IoT & IoE.png';
import nidhi_logo from '../Assests/images/Nidhi_Prayas.png';
import Tech_startup_logo from '../Assests/images/Tech_startup.png';
import venture_logo from '../Assests/images/Vennture_centre.png';
import qualcomm_logo from '../Assests/images/Qualcomm.png';
import { motion } from 'framer-motion';

function Supporters() {
    const supporters = [
        AIM_logo, Indus_logo, IOT_logo, nidhi_logo,Tech_startup_logo,venture_logo, qualcomm_logo
    ];

    return (
        <div className='supporters-box py-8 md:py-12 flex flex-col gap-10 items-center'>
            <p>Supporters</p>
            <div className='flex overflow-hidden [mask-image:linear-gradient(to_right,transparent,black,transparent)] p-5'>
                <motion.div className='flex gap-14 flex-none pr-14'
                    animate={{ translateX: '-50%' }}
                    transition={{
                        duration: 20,
                        repeat: Infinity,
                        ease: "linear",
                        repeatType: "loop"
                    }}>

                    {supporters.map((e, i) => (
                        <img src={e} alt={`supporter-${i}`} className='supporters-img' />
                    ))}

                </motion.div>
            </div>
        </div>
    );
}

export default Supporters;

import React from 'react'
import { Button } from '@mui/material'
import Pageoneimg from '../Assests/images/Page1_img.png'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { useState } from 'react';
import { Dialog, DialogActions, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function Evidence() {

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    return <section className='bg-[#483934]'>
        <div className='flex Evidence-box'>

            <div className='Evidence-img flex justify-center items-center'>
                <img src={Pageoneimg} />
            </div>

            <div className='flex flex-col justify-center gap-10'>
                <p>Empowering Personalized <br />
                    Longevity through <br />
                    Evidence based Ayurveda
                </p>

                <p>Ayur.Ai revolutionizes wellness by integrating Ayurveda with modern science to provide evidence-based, personalized care for optimal health and longevity</p>

                <div className='evidenc-btns gap-5'>

                    <div>
                        <Button
                            style={{
                                width: '100%',
                                textTransform: 'none',
                                borderRadius: '0',
                                backgroundColor: '#FDFDFD',
                                color: '#483934',
                                border: 'none',
                                boxSizing: 'border-box',
                                borderRadius: '5px',
                                fontFamily: "'Poppins', sans-serif",
                                fontWeight: 500,
                                padding: '10px'
                            }}

                            onClick={openPopup}
                        >
                            <PlayArrowIcon />&nbsp; Watch Video
                        </Button>
                    </div>

                    <div>
                        <Button

                            style={{
                                width: '100%',
                                textTransform: 'none',
                                borderRadius: '0',
                                border: '1px solid #FDFDFD ',
                                color: '#FDFDFD',
                                boxSizing: 'border-box',
                                backgroundColor: "transparent",
                                borderRadius: '5px',
                                fontFamily: "'Poppins', sans-serif",
                                fontWeight: 500,
                                padding: '10px'
                            }}

                            onClick={(e) => {
                                window.open('https://aiwellassets.s3.ap-south-1.amazonaws.com/asset/volume.pdf')
                            }}
                        >
                            <TextSnippetIcon /> &nbsp; Reinventing Ayurveda

                        </Button>

                    </div>

                </div>
            </div>


        </div>
        <Dialog
            open={isPopupOpen}
            onClose={closePopup}
            maxWidth="md"
            fullWidth
            style={{ overflow: "hidden" }}
        // fullScreen
        >
            <div style={{ overflow: "hidden" }} className='w-100 md:p-2 p-2'>
                <DialogActions>
                    <IconButton edge="end" color="inherit" onClick={closePopup} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogActions>


                <video controls controlsList="nodownload" className="w-100 md:p-2 h-100 p-2" width="100%" height="100%">
                    <source
                        src="https://aiwellassets.s3.ap-south-1.amazonaws.com/Your+paragraph+text.mp4"
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>


            </div>

        </Dialog>
    </section>
}

export default Evidence
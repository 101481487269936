import React, { useState, useEffect } from "react";
import MenuIcon from "../Assests/images/Hamburger.png";
import logo from '../Assests/images/Logo 4.png'
// import React, { useState } from "react";
import CloseIcon from '../Assests/images/close_icon.png'

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => setMenuOpen(!menuOpen);

    return (
        <header className="sticky top-0 z-10">

            <div
                className={`Navbar flex flex-row items-center justify-between
                    p-5 px-4 py-2 w-full transition-colors duration-300`}
            >
                <div className="navbar-logo md:invisible">
                    <img src={logo} alt="Logo" />
                </div>

                <div className="navbar-menu" style={{ justifySelf: "flex-end" }}>
                    <img
                        src={menuOpen ? CloseIcon : MenuIcon}
                        alt="Menu"
                        onClick={toggleMenu}
                        className={`cursor-pointer transform transition-transform duration-300 ${menuOpen ? "rotate-90" : "rotate-0"
                            }`}
                    />
                </div>
            </div>

            {menuOpen && (
                <div
                    className="fullscreen-menu fixed inset-0 z-40 flex items-center justify-center menu-section"
                    onClick={toggleMenu}
                >
                    <ul className="text-white text-center space-y-4 text-xl 
                    flex flex-col items-center justify-between gap-5 menu-items-section">

                        <a href="/"><li className="cursor-pointer">Home</li></a>
                        <a href="/#about">
                            <li className="cursor-pointer">About</li>
                        </a>
                        <a href="/#events">
                            <li className="cursor-pointer">Events</li>
                        </a>

                        <a href="/team">
                            <li className="cursor-pointer">Team</li>
                        </a>

                        <a href='/contact'>
                            <li className="cursor-pointer">Contact</li>
                        </a>
                    </ul>
                </div>
            )}

        </header>
    );
};

export default Navbar;


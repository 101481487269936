import React, { useState } from 'react'
import founderimg from '../Assests/images/Dr.Bala Pesala_img.png'
import mentor1 from '../Assests/images/Dr. Samir Brahmachari.png'
import mentor2 from '../Assests/images/Prof.Santanu Chaudhury.png'
import prathiban from '../Assests/images/Prathiban 1.png'
import moni from '../Assests/images/moniiii 1.png'
import vatchan from '../Assests/images/Vatchan.png'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import yedhu from '../Assests/images/Yedhu.png'
import hovered1 from '../Assests/images/Dr.Prathiban Hover Image.png'
import hovvred2 from '../Assests/images/Yedhu_hover_img.png'
import hovered3 from '../Assests/images/Vatchan_hover_img.png'
import hovered4 from '../Assests/images/Monika_hover_img.png'
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Link } from '@mui/material'
import { Dialog, DialogActions } from "@mui/material";

function Team() {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const [hoveredIndex, setHoveredIndex] = useState(null);

    const mentors = [{
        name: "Dr. Samir Brahmachari",
        position: "CHIEF SCIENTIFIC ADVISOR",
        info: "Genomics pioneer in India Former Director General, CSIR and Secretary GoI Founding Director, CSIR-IGIB",
        img: mentor1
    }, {
        name: "Prof.Santanu Chaudhury",
        position: "MENTOR",
        info: "IIT Delhi Former Director, IIT Jodhpur Pioneer in AI & Computer Vision",
        img: mentor2

    }]

    const Teams = [
        {
            name: "Dr. Prathiban",
            info: "An Ayurveda graduate with a doctorate in Medical Physiology from AIIMS, New Delhi, and a master's from KMC, Chennai. He served as a research associate on the Ayurgenomics project at IGIB, New Delhi, and is passionate about exploring Ayurveda through science-based basic and clinical research.",
            position: "Sr.Research Engineer",
            img: prathiban,
            cover_img: hovered1,
            link: "https://www.linkedin.com/in/prathiban-rengarajan-ph-d-a679526/"

        }, {
            name: "Yedhu Krishnan",
            info: "A graduate in CSE is a Designer & developer with a passion for UX design. Worked on a diverse set of projects from research to production, that include iOS, Android, web, SaaS, user research, data visualisation, and marketing design.",
            position: "Sr.Full Stack Developer",
            img: yedhu,
            cover_img: hovvred2,
            link: "https://www.linkedin.com/in/yedhukrrish/"
        }, {
            name: "Sree Vatchan",
            info: "A passionate full-stack developer with expertise in both front-end and back-end technologies, dedicated to building dynamic and scalable applications. Skilled in creating responsive user interfaces and seamless user experiences across platforms, capable of bringing ideas to life.",
            position: "Full Stack Developer",
            img: vatchan,
            cover_img: hovered3,
            link: "https://www.linkedin.com/in/sree-vatchan-6a0855246/"
        },
        {
            name: "Monika",
            info: "A creative visual and UX/UI designer passionate about crafting visually captivating, user-centric digital experiences that blend aesthetics with functionality. Skilled in designing intuitive interfaces and seamless interactions, they specialize in enhancing user engagement across Android, iOS, and web platforms.",
            position: "UX UI Designer",
            img: moni,
            cover_img: hovered4,
            link: "https://www.linkedin.com/in/monika-s-2b0141212/"
        }]

    return <div>

        <section className='bg-[#483934]'>
            <div className='Team-founder-page'>
                <p>Ayur.AI Founder</p>
                <p className='mt-5'>A visionary dedicated to innovating ancient wisdom by integrating it with modern science, driven by a commitment to advancing societal well-being.</p>
                <div className='Team-founder-section'>
                    <a href='https://www.linkedin.com/in/balapesala/' target='_blank'>
                        <div className='Team-founder-section-card'>
                            <img src={founderimg} />
                        </div>
                    </a>


                    <div>
                        <div>
                            <p className='mt-5'>Founder & CEO</p>
                            <p className='mt-5'>B.Tech, IIT Madras,</p>
                            <p className='mt-5'>PhD, University of California, Berkeley</p>
                        </div>
                    </div>

                    <div className='circle-container' onClick={openPopup}>

                        <div id="circle">
                            <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 300 300"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <defs>
                                    <path
                                        id="circlePath"
                                        d="M 150, 150 m -60, 0 a 60,60 0 1,1 120,0 a 60,60 0 1,1 -120,0"
                                    />
                                </defs>

                                {/* Stationary Inner Green Circle */}
                                <circle cx="150" cy="150" r="40" fill="#BED040" />

                                {/* Stationary Play Icon */}
                                <polygon
                                    points="140,135 170,150 140,165"
                                    fill="#483934"
                                    style={{ cursor: "pointer" }}
                                />

                                {/* Rotating Text */}
                                <g className="rotating-text">
                                    <use xlinkHref="#circlePath" fill="none" />
                                    <text fill="#FDFDFD">
                                        <textPath xlinkHref="#circlePath" textAnchor="middle" startOffset="50%">
                                            Modern Solutions, Ancient Insights
                                        </textPath>
                                    </text>
                                </g>
                            </svg>
                        </div>

                    </div>

                </div>
            </div>
        </section>

        <section>
            <div className='mentors-section-page'>
                <p className='text-center'>Ayur.AI Mentors</p>
                <p className='mt-5'>Guided by pioneers in genomics, AI, and innovation to <br />
                    revolutionize personalized wellness.</p>

                <div className='mentors-section'>
                    {
                        mentors.map((e, i) => {
                            return <div className='mentor-card'>
                                <div className='mentor-img'>
                                    <div><img src={e.img} /></div>
                                </div>
                                <div className='mentor-card-info mt-5'>
                                    <p className="mt-5">{e.name}</p>
                                    <p className="mt-5">{e.position}</p>
                                    <p className="mt-5">{e.info}</p>
                                </div>
                            </div>
                        })
                    }
                </div>

            </div>
        </section>

        <section className='bg-[#483934]'>

            <div className='Team-member-page'>
                <p className='text-center'>Ayur.AI Team</p>
                <p className='mt-5'>
                    Our experts leads the way in
                    integrating Ayurveda with
                    <br />advanced technology for impactful digital health solutions
                </p>

                <div className='team-members-section'>
                    {
                        Teams.map((e, i) => {
                            return <div style={hoveredIndex === i ? { backgroundColor: '#E9EECF' } : {}}
                                onMouseEnter={() => setHoveredIndex(i)}
                                onMouseLeave={() => setHoveredIndex(null)}>

                                {hoveredIndex === i ? <div className='team-member-info flex flex-col justify-between items-flex-start'>
                                    <div className='team-cover-img'>
                                        <img src={e.cover_img} />
                                    </div>
                                    <p className='mt-2'>{e.name}</p>
                                    <p className='mt-2'>{e.position}</p>
                                    <p className='mt-2'>{e.info}</p>
                                    <IconButton
                                        LinkComponent="a"
                                        href={e.link}
                                        target="_blank"
                                        style={{ color: "#483934" }}
                                    >
                                        <LinkedInIcon fontSize="large" />
                                    </IconButton>

                                </div> : <div>
                                    <img src={e.img}></img>
                                </div>
                                }

                            </div>
                        })
                    }
                </div>

            </div>

        </section>


        <Dialog
            open={isPopupOpen}
            onClose={closePopup}
            maxWidth="md"
            fullWidth
            style={{ overflow: "hidden" }}
        // fullScreen
        >
            <div style={{ overflow: "hidden" }} className='w-100 md:p-2 p-2'>
                <DialogActions>
                    <IconButton edge="end" color="inherit" onClick={closePopup} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogActions>


                <video controls controlsList="nodownload" className="w-100 md:p-2 h-100 p-2" width="100%" height="100%">
                    <source
                        src="https://aiwellassets.s3.ap-south-1.amazonaws.com/Your+paragraph+text.mp4"
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>


            </div>

        </Dialog>
    </div>
}

export default Team
import React, { useEffect, useState } from 'react';
import Carousel from 'react-carousel-elasticss';
import { getDatabase, ref, onValue } from "firebase/database";
import { Link } from '@mui/material';
import { Fade } from 'react-awesome-reveal';
import { db } from '../firebase';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function Articles() {
    const [articles, setArticles] = useState([]);


    const getData = () => {
        try {
            const dbInstance = getDatabase();
            const articlesRef = ref(dbInstance, `/articles`);
            onValue(articlesRef, (snapshot) => {
                const data = snapshot.val();
                setArticles(Object.values(data || {}));
            });
        } catch (error) {
            console.error("Error fetching articles:", error);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); 
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));


    let itemsToShow = 1;

    if (isMediumScreen) {
        itemsToShow = 2;
    } else if (isLargeScreen) {
        itemsToShow = 3;
    }

    return (
        <section>

            <div className='Events-section'>

                <p className='mt-15 text-center'>Articles</p>
                <div className="articles-section-carousel mt-5">
                    <Fade>
                        <Carousel itemsToShow={itemsToShow} itemsToScroll={itemsToShow} showArrows={false}>
                            {articles.slice(0,5).map((article, index) => (
                                <div key={index}>
                                    <Link
                                        href={article?.link}
                                        target="_blank"
                                        style={{ color: "black", textTransform: "none" }}
                                    >
                                        <div className="article-card">
                                            <img src={article?.image} alt={`Article ${index}`} />
                                            <p>{article?.header}</p>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </Carousel>
                    </Fade>
                </div>
            </div>
        </section>
    );
}

export default Articles;

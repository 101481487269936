import React, { useState } from 'react'
import { TextField, InputAdornment, Button } from '@mui/material';
import Contact_img from '../Assests/images/Contact us.png'
import axios from 'axios';
import { toast, Zoom } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function Contact() {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };




    const handleSendEmail = async () => {
        try {
            const { email, name, message } = formData

            const res = await axios.post('https://api.profile.ayurai.in/Contact-us', {
                email, name, message
            })

            if (res.status === 200) {
                toast.success("Sent Successfully", {
                    position: 'top-center',
                    limit: 1,
                    transition: Zoom,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    // theme: 'dark'
                });

                navigate('/')
            }
        }
        catch (err) {
            console.log(err)
        }
    };


    return <section>
        <div className='contact-us-section'>
            <div>
                <img src={Contact_img}></img>
            </div>
            <div className='contact-us-info'>
                <p>Contact Us</p>
                <p className='mt-5'>For any Queries</p>
                <div className='mt-5 flex flex-col justify-between'>

                    <TextField
                        name="name"
                        label="Name"
                        placeholder="Enter your name"
                        value={formData.name}
                        onChange={handleChange}
                        color="primary"
                        fullWidth
                        margin="normal"
                        sx={{
                            backgroundColor: '#EFEFEF',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#FDFDFD',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#FDFDFD',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#FDFDFD',
                                },
                                backgroundColor: '#EFEFEF',
                            },
                            '& .MuiInputBase-input': {
                                // color: '#FDFDFD',
                            },
                        }}
                    />

                    <TextField
                        name="email"
                        label="Email"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={handleChange}
                        color="primary"
                        fullWidth
                        margin="normal"
                        sx={{
                            backgroundColor: '#EFEFEF',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#FDFDFD',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#FDFDFD',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#FDFDFD',
                                },
                                backgroundColor: '#EFEFEF',
                            },
                            '& .MuiInputBase-input': {
                                // color: '#FDFDFD',
                            },
                        }}
                    />

                    <TextField
                        name="message"
                        label="Message"
                        placeholder="Enter your message"
                        value={formData.message}
                        onChange={handleChange}
                        color="primary"
                        fullWidth
                        margin="normal"
                        multiline
                        rows={5}
                        sx={{
                            backgroundColor: '#EFEFEF',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#FDFDFD',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#FDFDFD',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#FDFDFD',
                                },
                                backgroundColor: '#EFEFEF',
                            },
                            '& .MuiInputBase-input': {
                                // color: '#FDFDFD',
                            },
                        }}
                    />

                    <div className='flex items-center justify-end'>
                        <Button
                            variant="contained"
                            onClick={handleSendEmail}
                            sx={{
                                marginTop: '16px',
                                backgroundColor: '#483934',
                                color: '#FFFFFF',
                                textTransform: 'none',
                            }}
                        >
                            Send
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </section>
}

export default Contact
import React, { useState } from 'react'
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { carouselData } from './carouselData';
import {
    StackedCarousel,
    ResponsiveContainer
} from "react-stacked-center-carousel";

import { Slide } from "./Slide";
import { Fab } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

function Events() {
    const ref = React.useRef(StackedCarousel);
    const [centerSlideDataIndex, setCenterSlideDataIndex] = React.useState(0);
    const onCenterSlideDataIndexChange = (newIndex) => {
        setCenterSlideDataIndex(newIndex);
    };

    return <section className='bg-[#483934]' id='events'>
        <div className='Events-section'>
            <p className='mt-15 text-center'>Events</p>
            <div className="card">
                <div style={{ width: "100%", height: "100%", position: "relative" }} >
                    <ResponsiveContainer
                        carouselRef={ref}
                        render={(width, carouselRef) => {
                            return (
                                <StackedCarousel
                                    ref={carouselRef}
                                    slideComponent={Slide}
                                    slideWidth={300}
                                    carouselWidth={width}
                                    data={carouselData}
                                    maxVisibleSlide={5}
                                    disableSwipe
                                    customScales={[1, 0.85, 0.7, 0.6]}
                                    onActiveSlideChange={onCenterSlideDataIndexChange}
                                    transitionTime={450}
                                />
                            );
                        }}
                    />
                    <Fab
                        className="card-button left"
                        size="small"
                        onClick={() => ref.current?.goBack()}
                    >
                        <KeyboardArrowLeftIcon style={{ fontSize: 30 }} />
                    </Fab>
                    <Fab
                        className="card-button right"
                        size="small"
                        onClick={() => ref.current?.goNext()}
                    >
                        <KeyboardArrowRightIcon style={{ fontSize: 30 }} />
                    </Fab>
                </div>
            </div>
        </div>
    </section>
}

export default Events
import React, { useState, useRef } from 'react'
import logo from '../Assests/images/Logo 2.png'
import Lottie from 'lottie-react'
import Aiwelllogo from '../Assests/images/Aiwell_logo.png'
import Homepageanimation from '../Assests/raw/Property 1=Frame 427319626.json'
import arrownext from '../Assests/images/landing_card_arrow.png'
import Evidence from '../Pages/Evidence'
import Discover from '../Pages/Discover'
import Supporters from '../Pages/Supporters'
import Ayurai_Products from '../Pages/Ayurai_Products'
import Events from '../Pages/Events'
import About from '../Pages/About'
import { motion } from 'framer-motion'
import useScrollSnap from "react-use-scroll-snap";
import { Article, Opacity } from '@mui/icons-material'
import Articles from '../Pages/Articles'
import Publications from '../Pages/Publications'



function Homepage() {

  const [showNextAnimation, setShowNextAnimation] = useState(false);

  const sectionVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0 },
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollRef = useRef(null);

  useScrollSnap({ ref: scrollRef, duration: 50, delay: 20 })

  return <div className="" ref={scrollRef}>

    <section className='bg[#FAF8F3]' >
      <div className='Homepage'>

        <div className='Homepage-contanier'>
          <div className='Homepage-content'>
            <div className='Homepage-logo'>
              <img src={logo} />
            </div>

            <motion.div
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 }
              }
              }
              initial={'hidden'}
              animate={'visible'}
              className='mt-10 homepage-title text-center'>
              <p >Integrative Wellness & Medicine Company </p>
            </motion.div>


            <motion.div
              className="flex items-center gap-5 eviden-btns"
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 }
              }
              }
              initial={'hidden'}
              animate={'visible'}
              transition={{

                delay: 0.5,
              }}
            >

              <motion.div
                className="Aiwell-logo-box"
                variants={{
                  hidden: { opacity: 0, y: 75 },
                  visible: { opacity: 1, y: 0 }
                }
                }
                initial={'hidden'}
                animate={'visible'}

                transition={{

                  duration: 0.8,
                }}
              >
                <img src={Aiwelllogo} alt="Aiwell Logo" />
              </motion.div>

              <motion.div
                className="flex items-center aiwell-landing-btn justify-between md:justify-evenly"
                variants={{
                  hidden: { opacity: 0, y: 75 },
                  visible: { opacity: 1, y: 0 }
                }
                }
                initial={'hidden'}
                animate={'visible'}
                transition={{
                  type: "spring",
                  stiffness: 120,
                  damping: 15,
                  duration: 0.8,
                }}
              >
                <p className="pl-5 md:pl-0">
                  Start Your <br /> Wellness <br /> Journey Here
                </p>

                <a href='https://aiwell.in/' target='_blank'><img src={arrownext} alt="Arrow Next" /></a>

              </motion.div>
            </motion.div>

            <div className='follow-us-icons'>

              <p>Follow us on</p>

              <div className='social-media' style={{ flexDirection: "row" }}>

                <div className='flex flex-row items-center' onClick={() => window.open('https://www.instagram.com/ayur.ai_/')}>
                  <div>
                    <i class="fa-brands fa-instagram"></i>
                  </div>

                  <p className='hidden'>Instagram</p>

                </div>

                <div className='flex flex-row  items-center' onClick={() => window.open('https://www.facebook.com/ayuraiinnovations')}>

                  <div>
                    <i class="fa-brands fa-facebook-f"></i>
                  </div>

                  <p className='hidden'>Facebook</p>

                </div>

                <div className='flex flex-row items-center' onClick={() => window.open('https://www.linkedin.com/company/ayurai/')}>

                  <div>
                    <i class="fa-brands fa-linkedin-in"  ></i>
                  </div>

                  <p className='hidden'>Linkedin</p>

                </div>

                <div className='flex flex-row  items-center' onClick={() => window.open('https://twitter.com/Ayur_AI')}>

                  <div>
                    <i class="fa-brands fa-x-twitter" onClick={() => window.open('https://twitter.com/Ayur_AI')}></i>
                  </div>

                  <p className='hidden'>Twitter</p>

                </div>
              </div>
            </div>

          </div>

          <motion.div
            className="homepage-animation"

            initial={{ y: -200, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}

            transition={{
              type: "spring",
              stiffness: 100,
              damping: 10,
              duration: 1,
              bounce: 0.5,
            }}

            onAnimationComplete={() => setShowNextAnimation(true)}

          >
            <Lottie animationData={Homepageanimation} />
          </motion.div>
        </div>
      </div>
    </section>
    <Evidence />
    <Discover />
    <About />
    <Ayurai_Products />
    <Events />
    <Publications />
    <Articles />
    <Supporters />
  </div>



}

export default Homepage
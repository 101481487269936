import { IconButton } from "@mui/material";
import React from "react";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { StackedCarouselSlideProps } from "react-stacked-center-carousel";

export const Slide = React.memo(function (StackedCarouselSlideProps) {
    const {
        data,
        dataIndex,
        isCenterSlide,
        swipeTo,
        slideIndex
    } = StackedCarouselSlideProps;




    return (
        <div className="card-card" draggable={false}>
            <div className={`cover fill ${isCenterSlide ? "off" : "on"}`}>
                <div
                    className="card-overlay fill"
                    onClick={() => {
                        if (!isCenterSlide) swipeTo(slideIndex);
                    }}
                />
            </div>
            <div className="detail fill">
                <div className="discription">
                    <div className="event-card-main">
                        {
                            data[dataIndex]?.iframe ?
                                <div dangerouslySetInnerHTML={{ __html: data[dataIndex]?.iframe }} />
                                : <div>
                                    <img src={data[dataIndex]?.img} />
                                </div>
                        }

                    </div>
                    <div className="event-card-content flex flex-col gap-3 mt-5 h-100" >
                        <p>{data[dataIndex]?.date}</p>
                        <p>{data[dataIndex]?.title}</p>
                        <p>{data[dataIndex]?.description}</p>




                    </div>

                    <div style={{ alignSelf: "flex-end", justifySelf: "flex-end", marginTop: "auto" }}>
                        {
                            data[dataIndex]?.link &&
                            <IconButton
                                sx={{
                                    backgroundColor: "#BED040",
                                    color: "#FDFDFD",
                                    '&:hover': {
                                        backgroundColor: "#BED040",
                                    },
                                }}

                                onClick={() => {
                                    window.open(data[dataIndex]?.link)
                                }}
                            >
                                <ArrowRightAltIcon />
                            </IconButton>

                        }
                    </div>

                </div>
            </div>
        </div>
    );
});

import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from './Navbar'
import Homepage from './Homepage'
import Footer from './Footer'

function HomeScreen() {
    return (
        <>
            <Navbar />
            <Outlet />
            <Footer />
        </>



    )
}

export default HomeScreen